import { render, staticRenderFns } from "./DeliveryGoals.vue?vue&type=template&id=b4a73d5e&scoped=true&"
import script from "./DeliveryGoals.vue?vue&type=script&lang=ts&"
export * from "./DeliveryGoals.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a73d5e",
  null
  
)

export default component.exports