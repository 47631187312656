
import { Component, Ref, Watch } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Company from '@/models/Company'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import WebMessage from '@/models/WebMessage'
import company_home_table_fields from './company-home-table-fields'
import ViewModel from '../../models/ViewModel'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
  },
})
export default class CompanyHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public company: Company = new Company()

  public query: string[] = ['is_not:archived']

  public fields: any = []

  public page_size: number = 25

  public sort_by: string = 'updated_at'

  public show_filter_helper: boolean = false

  public sort_desc: boolean = true

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public clipboard: string = ''

  // dataTable field filters
  public fieldFilters: any = {
    created_at: '',
    updated_at: '',
    type: '',
  }

  public modal: any = {
    delete: false,
  }

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public query_settings: any = {
    company_fields: [
      {
        name: 'parent',
        key: 'parent_id',
        description: 'Filter by Parent Company',
      },
    ],
    custom_fields: [
      {
        name: 'type:advertiser',
        value: 'type:advertiser',
        description: 'Include only advertisers',
      },
      {
        name: 'type:publisher',
        value: 'type:publisher',
        description: 'Include only publishers',
      },
      {
        name: 'type:agency',
        value: 'type:agency',
        description: 'Include only agencies',
      },
      {
        name: 'type:station',
        value: 'type:station',
        description: 'Include only stations',
      },
      {
        name: 'is:archived',
        value: 'is:archived',
        description: 'Include only archived entities',
      },
      {
        name: 'is_not:archived',
        value: 'is_not:archived',
        description: 'Include only active entities',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public companies(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return Company.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public confirmDelete(company: Company) {
    this.company = company
    this.modal.delete = true
  }

  public deleteCompany() {
    this.company.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public createCompany() {
    this.$router.push('/app/company')
  }

  public editCompany(id: string) {
    this.$router.push(`/app/company/${id}`)
  }

  public mounted() {
    this.loadFilters()
    this.fields = company_home_table_fields
  }

  public checkDetails(item: any) {
    this.$router.push({ name: 'company-details', params: { id: item.id } })
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'companies',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('companies').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'companies',
      data: null,
    })
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {
      created_at: '',
      updated_at: '',
      type: '',
    }
    this.query = []
    this.filter_mode = 'exclusive'
    this.clearFilters()
    this.refresh()
  }

  public copy(val: string) {
    this.clipboard = val
    // @ts-ignore
    this.$refs.clipboard.setAttribute('type', 'text')

    setTimeout(() => {
      // @ts-ignore
      this.$refs.clipboard.select()

      let status = false

      try {
        status = document.execCommand('copy')
      } catch (e) {
        status = false
      }

      // @ts-ignore
      this.$refs.clipboard.setAttribute('type', 'hidden')

      if (status) {
        WebMessage.success('ID was copied to your clipboard!')
      } else {
        WebMessage.error(
          `Ops! We were not able to copy to your clipboard, please copy: ${this.clipboard}`,
        )
      }
    }, 100)
  }
}
