
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'

import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import { macros, replaceMacros } from '@/models/Util'
import SelectPicker from '../SelectPicker/SelectPicker.vue'
import FormInput from '../FormInput/FormInput.vue'
import DataTable from '../DataTable/index.vue'
import IconAction from '../IconAction/IconAction.vue'

@Component({
  components: {
    SelectPicker,
    FormInput,
    DataTable,
    IconAction,
  },
})
export default class EventTracker extends ViewModel {
  @Prop()
  public any!: any

  @Prop()
  public value!: any

  @Prop({ default: false })
  public disabled!: boolean

  public local_value: any = []

  public table_fields: any = [
    {
      key: 'event',
      label: 'Event',
      class: 'text-center align-middle text-capitalize',
      show: true,
      thClass: 'align-middle text-center',
      thStyle: 'width:300px!important',
    },
    {
      key: 'url',
      label: 'Url',
      class: 'text-center align-middle text-capitalize',
      show: true,
      thClass: 'align-middle text-center',
    },
    {
      key: 'actions',
      label: ' ',
      class: 'text-center align-middle text-capitalize',
      show: true,
      thStyle: { width: '100px' },
      thClass: 'align-middle text-center',
    },
  ]

  public event_list: any = [
    { name: 'start', value: 'start' },
    { name: 'first Quartile', value: 'firstQuartile' },
    { name: 'Midpoint', value: 'midpoint' },
    { name: 'Third Quartile', value: 'thirdQuartile' },
    { name: 'Complete', value: 'complete' },
    { name: 'Mute', value: 'mute' },
    { name: 'Unmute', value: 'unmute' },
    { name: 'Pause', value: 'pause' },
    { name: 'Resume', value: 'resume' },
    { name: 'Rewind', value: 'rewind' },
    { name: 'Fullscreen', value: 'fullscreen' },
    { name: 'Close', value: 'close' },
    { name: 'AcceptInvitation', value: 'acceptInvitation' },
    { name: 'Skip', value: 'skip' },
    { name: 'Impression', value: 'impression' },
  ]

  @Watch('local_value', { deep: true })
  public onLocalValueChange(val: any) {
    if (val) {
      this.$emit('input', val)
    }
  }

  public mounted() {
    this.local_value = this.value
  }

  public addEventTracker() {
    this.local_value.push({
      events: '',
      url: null,
    })
  }

  public remove(index: number) {
    this.local_value.splice(index, 1)
  }

  public get macros() {
    return macros
  }

  public replaceMacros(index: number) {
    let url = this.local_value[index].url
    if (!url) return
    url = replaceMacros(url)
    this.local_value[index].url = url
  }
}
