
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import DeliveryGoal from '@/models/DeliveryGoal'
import ViewModel from '@/models/ViewModel'
import { watch } from 'fs'
import moment from 'moment'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'

@Component({
  components: {
    DatePicker,
    FormInput,
    IconAction,
  },
})
export default class DeliveryGoals extends ViewModel {
  @Prop({ default: 'delivery_goals' })
  public type!: string

  @Prop()
  public value!: any // company.delivery_goals || company.delivery_caps

  public local_value: any = []

  public notificating: boolean = false

  public local_errors: any = []

  @Watch('local_value', { deep: true })
  public onLocalValueChange(value: any) {
    this.$emit('input', value)
    setTimeout(() => {
      this.checkForOverlaps()
    }, 500)
  }

  public get goals_dates() {
    return {
      ...this.local_value.map((v: any) => ({ ...v })),
    }
  }

  public addDeliveryGoal() {
    let deliver: DeliveryGoal = new DeliveryGoal()
    this.local_value.push(deliver)
  }

  public remove(index: number) {
    this.local_value.splice(index, 1)
  }

  public mounted() {
    this.local_value = this.value

    if (this.local_value.length) {
      setTimeout(() => {
        this.checkForOverlaps()
      }, 500)
    }
  }

  public notify(msg: any = 'Overlapping', title = '') {
    if (this.notificating) return
    this.notificating = true
    setTimeout(() => {
      this.notificating = false
    }, 6100)
    this.$snotify.error(msg, title, {
      id: 'notification',
      timeout: 6000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      preventDuplicates: true,
      maxOnScreen: 1,
    })
  }

  public toggleErrors(index: any[], remove: boolean = false) {
    index.forEach((i: number) => {
      let card: any = this.$refs[`${this.type}_card_${i}`]

      if (card.length) card = card[0]
      else return

      let hasClass = card.classList.contains('border-danger')

      if (card && !hasClass) {
        card.classList.add('border', 'border-danger')
      }
      if (remove) {
        card.classList.remove('border-danger')
      }
    })
  }

  public addErrors(index: number, msg: any = ['Overlapping date']) {
    let s_date_vid = `delivery-start-${index}`
    let e_date_vid = `delivery-end-${index}`
    let error = {
      [s_date_vid]: msg,
      [e_date_vid]: msg,
    }
    this.$emit('errors', error)
  }

  public comparingRulesOrverlaps(goal: any, comparing_goal: any) {
    if (
      goal.start_at.isBetween(comparing_goal.start_at, comparing_goal.end_at)
      || goal.start_at.isSame(comparing_goal.start_at)
      || goal.end_at.isBetween(comparing_goal.start_at, comparing_goal.end_at)
      || (goal.end_at.isSame(comparing_goal.end_at)
        && comparing_goal.start_at.isBetween(goal.start_at, goal.end_at))
      || comparing_goal.end_at.isBetween(goal.start_at, goal.end_at)
      || comparing_goal.start_at.isSame(goal.start_at)
      || comparing_goal.end_at.isSame(goal.end_at)
    ) {
      return true
    }
    return false
  }

  public checkForOverlaps() {
    this.$emit('hasErrors', false)

    for (let index = 0; index < this.local_value.length; index++) {
      if (this.local_value.length === 1) {
        this.addErrors(index, [])
        this.toggleErrors([index], true)
        this.$emit('hasErrors', false)
        break
      }
      const goal = {
        ...this.local_value[index],
        start_at: moment(this.local_value[index].start_at),
        end_at: moment(this.local_value[index].end_at),
      }

      for (let i = 0; i < this.local_value.length; i++) {
        if (i !== index) {
          const comparing_goal = {
            ...this.local_value[i],
            start_at: moment(this.local_value[i].start_at),
            end_at: moment(this.local_value[i].end_at),
          }

          if (this.comparingRulesOrverlaps(goal, comparing_goal)) {
            this.toggleErrors([index, i])
            this.notify('Overlapping dates')
            // this.addErrors(index)
            this.$emit('hasErrors', true)
          } else {
            this.toggleErrors([index, i], true)
            // this.addErrors(index, [])
            // this.addErrors(i, [])
          }
        }
      }
    }
  }
}
