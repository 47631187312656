export const invoice_type_options = [
  { text: 'Default', value: 'default' },
  { text: 'Media Ocean', value: 'media_ocean' },
  { text: 'Strata', value: 'strata' },
]

export const invoice_group_options = [
  { text: 'Single Invoice', value: 'single' },
  { text: 'One Invoice per Advertiser', value: 'advertiser' },
  { text: 'One Invoice per Media Plan/Order', value: 'media_plan' },
  // { text: 'One Invoice per Media Plan', value: 'invoice_per_media_plan' },
  // { text: 'Group Media Plans into a single Invoice', value: 'media_plan' },
  // { text: 'Group Media Plans into a single Invoice & a single Item', value: 'single' },

  // { text: 'Media Plan Item', value: 'media_plan_item' }, // Ignore
]

export const invoice_detail_options = [
  { text: 'Merge all into a single line', value: 'single' },
  { text: 'One line per Media Plan/Order', value: 'media_plan' },
  { text: 'One line per Line Item', value: 'media_plan_item' },
]

export const query_settings = {
  company_fields: [
    {
      name: 'agency',
      key: 'agency_id',
      type: 'agency',
    },
    {
      name: 'advertiser',
      key: 'advertiser_id',
      type: 'advertiser',
    },
  ],
  custom_fields: [],
}
