
import Widget from '@/components/Widget/Widget.vue'
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import Profile from '@/models/Profile'
import FeatureAccess from '@/models/FeatureAccess'
import Company from '@/models/Company'
import User from '@/models/User'
import ViewModel from '@/models/ViewModel'
import { permissionOptions } from '@/models/DefaultPermissions'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'

@Component({
  components: {
    Widget,
    SelectPicker,
  },
})
export default class AccessControl extends ViewModel {
  public base_profile: Profile = new Profile()

  public local_access: FeatureAccess[] = []

  @Prop()
  public access!: FeatureAccess[]

  @Prop({ default: 'profile' })
  public mode!: string

  @Prop({ default: false })
  public root!: boolean

  @Prop()
  public company!: Company

  private models: string[] = []

  private actions: string[] = []

  public ready: boolean = false

  public getFeatureOptions(key: string) {
    // @ts-ignore
    return permissionOptions[key]
  }

  public onPermissionChange(model: string, action: string, access: boolean) {
    if (model == 'all' && action == 'all') {
      this.local_access.forEach(i => {
        if (this.mode == 'profile' && access && this.company.can(i.model, i.action)) {
          i.access = true
        } else if (this.mode == 'company' && access && this.user.company!.can(i.model, i.action)) {
          i.access = true
        } else {
          i.access = false
        }
      })
    } else if (model == 'profile' && action != 'view' && access) {
      this.local_access.forEach(i => {
        if (access && this.company.can(i.model, i.action)) {
          i.access = true
        } else {
          i.access = false
        }
      })
    } else if (access && action != 'view') {
      this.local_access[this.getAccessIndex(model, 'view')].access = true
    } else if (!access && action == 'view') {
      let idx = this.getAccessIndex(model, 'edit')

      if (idx >= 0) {
        this.local_access[idx].access = false
      }

      idx = this.getAccessIndex(model, 'delete')

      if (idx >= 0) {
        this.local_access[idx].access = false
      }
    }

    this.$emit('update:access', this.local_access)
  }

  public getAccessIndex(model: string, action: string) {
    return this.local_access.findIndex(item => item.model === model && item.action === action)
  }

  public mounted() {
    this.access.forEach((fa: FeatureAccess) => {
      let permission = fa.access

      if (this.root) {
        if (this.mode == 'company') {
          permission = true
        } else {
          const company_permission = this.company.access.find(
            p => p.model === fa.model && p.action === fa.action,
          )

          permission = company_permission ? company_permission.access : false
        }
      }

      this.local_access.push(new FeatureAccess(fa.model, fa.action, permission, fa.options))
    })

    this.base_profile.access
      .map(item => item.model)
      .filter((value, index, self) => self.indexOf(value) === index)
      .forEach(item => {
        // @ts-ignore
        if (this.user.company.can(item)) {
          this.models.push(item)
        }
      })

    this.actions = this.base_profile.access
      .map(item => item.action)
      .filter((value, index, self) => self.indexOf(value) === index)

    this.ready = true
  }
}
