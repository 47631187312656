import { interpolateRgbBasis } from 'd3-interpolate'
import { startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { scaleSequential } from 'd3-scale'
import WebMessage from './WebMessage'

// export const colorPallet = interpolateRgbBasis(['#E43E30', '#323E48'])
// export const colorPalletReversed = interpolateRgbBasis(['#E43E30', '#323E48'].reverse())

export const colorPallet = [
  '#555c6e',
  '#00C1DE',
  '#b0ca36',
  '#f2644f',
]

export const buildColorPool = (size: number, reverse: boolean = false) => {
  let pallet = [...colorPallet]

  if (reverse) {
    pallet = pallet.reverse()
  }

  if (size <= pallet.length) {
    pallet = pallet.slice(0, size)

    return (t: number) => pallet[t]
  }

  return scaleSequential(interpolateRgbBasis(pallet)).domain([0, size])
}

export const formatText = (
  value: string | number,
  format: string = 'text',
  allowAbbreviate: boolean = false,
  fallback: string | null = null,
): string => {
  if (!value) return fallback ?? (typeof value === 'string' ? 'N/A' : '-')

  if (format === 'percentage') {
    return numeral(value).format('0.00%')
  }
  if (format === 'percentage_rounded') {
    return numeral(value).format('0%')
  }

  if (format === 'abbreviate' || (allowAbbreviate && format === 'numeral')) {
    return numeral(value).format('0.00a')
  }

  if (format === 'numeral' || format === 'number') {
    return numeral(value).format('0,0')
  }

  if (format === 'currency') {
    return numeral(value).format('$0,0.00')
  }
  if (format === 'currency_abbreviate') {
    return numeral(value).format('$0.00a')
  }
  if (format === 'date') {
    return moment(value).format('MMMM, Do YYYY')
  }
  if (format === 'month') {
    return moment(value).format('MMMM YYYY')
  }
  if (format === 'datetime') {
    return moment(value).format('MMMM, Do YYYY hh:mm:ss')
  }
  if (format === 'time') {
    return moment(value).format('hh:mm:ss')
  }

  if (typeof value === 'string') {
    if (format === 'uppercase') {
      return value.toUpperCase()
    }
  }

  return typeof value === 'string' ? startCase(value) : startCase(String(value))
}

export const macros: any = {
  '%%CACHEBUSTER%%': {
    description:
      "A random number that changes every time the ad is loaded. This is useful for preventing caching of the ad's content.",
  },
  '%%VAST_VERSION%%': { description: 'The version of VAST that is being used.' },
  '%%ADUNIT_ID%%': { description: 'The UUID of the ad unit.', variants: ['AD_UNIT_ID'] },
  '%%ADVERTISER_ID%%': { description: 'The UUID of the advertiser.' },
  '%%ORDER_ID%%': { description: 'The UUID of the order.' },
  '%%LINE_ITEM_ID%%': { description: 'The UUID of the line item.' },
  '%%CREATIVE_ID%%': { description: 'The UUID of the creative.' },
  '%%HEIGHT%%': { description: 'The creative height.' },
  '%%WIDTH%%': { description: 'The creative width.' },
  '%%LATLONG%%': { description: 'The approximated latitude and longitude of the user.' },
  '%%ADVERTISING_IDENTIFIER_PLAIN%%': {
    description: 'The user UUID.',
    variants: ['DEVICE_IDENTIFIER'],
  },
  '%%ADVERTISING_IDENTIFIER_TYPE%%': {
    description: 'The advertising identifier type.',
    variants: ['DEVICE_TYPE'],
  },
  '%%REQUEST_ID%%': {
    description: 'The UUID of the request.',
    variants: ['TRANSACTIONID', 'TRANSACTION_ID'],
  },
  '%%MEDIA_PLAN_ID%%': { description: 'Media Plan ID.' },
  '%%MEDIA_PLAN_ITEM_ID%%': { description: 'Media Plan Item ID.' },
  '%%PUBLISHER_ID%%': { description: 'Publisher ID.' },
  '%%PUBLISHER_NAME%%': { description: 'Publisher Name.' },
  '%%I_SPOT_ADVERTISER_ID%%': {
    description:
      "iSpot Advertiser ID. If Advertiser does not have iSpot ID the URL using this macro won't be included.",
  },
  '%%APP_BUNDLE_ID%%': { description: "The app's bundle ID." },
  '%%APP_NAME%%': { description: "The app's name." },
  '%%ISCI_CODE%%': { description: 'Creative ISCI code.' },
}

export const replaceMacros = (uri: string): string => {
  const originalUri = uri

  for (const macro in macros) {
    let m = macro.replaceAll('%%', '')
    uri = uri.replaceAll(`[${m}]`, macro)
    uri = uri.replaceAll(`$$${m}$$`, macro)
    uri = uri.replaceAll(`$${m}$`, macro)
    m = m.replaceAll('_', ' ')
    uri = uri.replaceAll(`[${m}]`, macro)
    uri = uri.replaceAll(`$$${m}$$`, macro)
    uri = uri.replaceAll(`$${m}$`, macro)
    m = m.replaceAll(' ', '')
    uri = uri.replaceAll(`[${m}]`, macro)
    uri = uri.replaceAll(`$$${m}$$`, macro)
    uri = uri.replaceAll(`$${m}$`, macro)

    if (macros[macro].variants) {
      for (let variant of macros[macro].variants) {
        uri = uri.replaceAll(`[${variant}]`, macro)
        uri = uri.replaceAll(`$$${variant}$$`, macro)
        uri = uri.replaceAll(`$${m}$`, macro)
        variant = variant.replaceAll('_', ' ')
        uri = uri.replaceAll(`[${variant}]`, macro)
        uri = uri.replaceAll(`$$${variant}$$`, macro)
        uri = uri.replaceAll(`$${m}$`, macro)
        variant = variant.replaceAll(' ', '')
        uri = uri.replaceAll(`[${variant}]`, macro)
        uri = uri.replaceAll(`$$${variant}$$`, macro)
        uri = uri.replaceAll(`$${m}$`, macro)
      }
    }
  }

  if (uri.includes('$') && originalUri !== uri) {
    let msg = 'The URI might have some invalid macros, please review the URI.'
    if (originalUri !== uri) {
      msg = 'Macros replaced, but the URI might have some invalid macros, please review the URI.'
    }
    WebMessage.warning(msg)
  } else if (originalUri !== uri) {
    WebMessage.success('Macros replaced successfully, please review the URI.')
  }

  return uri
}
