export default [
  {
    name: 'Afghanistan',
    value: 'AF',
    flag: '🇦🇫',
  },
  {
    name: 'Aland Islands',
    value: 'AX',
    flag: '🇦🇽',
  },
  {
    name: 'Albania',
    value: 'AL',
    flag: '🇦🇱',
  },
  {
    name: 'Algeria',
    value: 'DZ',
    flag: '🇩🇿',
  },
  {
    name: 'American Samoa',
    value: 'AS',
    flag: '🇦🇸',
  },
  {
    name: 'Andorra',
    value: 'AD',
    flag: '🇦🇩',
  },
  {
    name: 'Angola',
    value: 'AO',
    flag: '🇦🇴',
  },
  {
    name: 'Anguilla',
    value: 'AI',
    flag: '🇦🇮',
  },
  {
    name: 'Antarctica',
    value: 'AQ',
    flag: '🇦🇶',
  },
  {
    name: 'Antigua And Barbuda',
    value: 'AG',
    flag: '🇦🇬',
  },
  {
    name: 'Argentina',
    value: 'AR',
    flag: '🇦🇷',
  },
  {
    name: 'Armenia',
    value: 'AM',
    flag: '🇦🇲',
  },
  {
    name: 'Aruba',
    value: 'AW',
    flag: '🇦🇼',
  },
  {
    name: 'Australia',
    value: 'AU',
    flag: '🇦🇺',
  },
  {
    name: 'Austria',
    value: 'AT',
    flag: '🇦🇹',
  },
  {
    name: 'Azerbaijan',
    value: 'AZ',
    flag: '🇦🇿',
  },
  {
    name: 'The Bahamas',
    value: 'BS',
    flag: '🇧🇸',
  },
  {
    name: 'Bahrain',
    value: 'BH',
    flag: '🇧🇭',
  },
  {
    name: 'Bangladesh',
    value: 'BD',
    flag: '🇧🇩',
  },
  {
    name: 'Barbados',
    value: 'BB',
    flag: '🇧🇧',
  },
  {
    name: 'Belarus',
    value: 'BY',
    flag: '🇧🇾',
  },
  {
    name: 'Belgium',
    value: 'BE',
    flag: '🇧🇪',
  },
  {
    name: 'Belize',
    value: 'BZ',
    flag: '🇧🇿',
  },
  {
    name: 'Benin',
    value: 'BJ',
    flag: '🇧🇯',
  },
  {
    name: 'Bermuda',
    value: 'BM',
    flag: '🇧🇲',
  },
  {
    name: 'Bhutan',
    value: 'BT',
    flag: '🇧🇹',
  },
  {
    name: 'Bolivia',
    value: 'BO',
    flag: '🇧🇴',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'BA',
    flag: '🇧🇦',
  },
  {
    name: 'Botswana',
    value: 'BW',
    flag: '🇧🇼',
  },
  {
    name: 'Bouvet Island',
    value: 'BV',
    flag: '🇧🇻',
  },
  {
    name: 'Brazil',
    value: 'BR',
    flag: '🇧🇷',
  },
  {
    name: 'British Indian Ocean Territory',
    value: 'IO',
    flag: '🇮🇴',
  },
  {
    name: 'Brunei',
    value: 'BN',
    flag: '🇧🇳',
  },
  {
    name: 'Bulgaria',
    value: 'BG',
    flag: '🇧🇬',
  },
  {
    name: 'Burkina Faso',
    value: 'BF',
    flag: '🇧🇫',
  },
  {
    name: 'Burundi',
    value: 'BI',
    flag: '🇧🇮',
  },
  {
    name: 'Cambodia',
    value: 'KH',
    flag: '🇰🇭',
  },
  {
    name: 'Cameroon',
    value: 'CM',
    flag: '🇨🇲',
  },
  {
    name: 'Canada',
    value: 'CA',
    flag: '🇨🇦',
  },
  {
    name: 'Cape Verde',
    value: 'CV',
    flag: '🇨🇻',
  },
  {
    name: 'Cayman Islands',
    value: 'KY',
    flag: '🇰🇾',
  },
  {
    name: 'Central African Republic',
    value: 'CF',
    flag: '🇨🇫',
  },
  {
    name: 'Chad',
    value: 'TD',
    flag: '🇹🇩',
  },
  {
    name: 'Chile',
    value: 'CL',
    flag: '🇨🇱',
  },
  {
    name: 'China',
    value: 'CN',
    flag: '🇨🇳',
  },
  {
    name: 'Christmas Island',
    value: 'CX',
    flag: '🇨🇽',
  },
  {
    name: 'Cocos (Keeling) Islands',
    value: 'CC',
    flag: '🇨🇨',
  },
  {
    name: 'Colombia',
    value: 'CO',
    flag: '🇨🇴',
  },
  {
    name: 'Comoros',
    value: 'KM',
    flag: '🇰🇲',
  },
  {
    name: 'Congo',
    value: 'CG',
    flag: '🇨🇬',
  },
  {
    name: 'Democratic Republic of the Congo',
    value: 'CD',
    flag: '🇨🇩',
  },
  {
    name: 'Cook Islands',
    value: 'CK',
    flag: '🇨🇰',
  },
  {
    name: 'Costa Rica',
    value: 'CR',
    flag: '🇨🇷',
  },
  {
    name: "Cote D'Ivoire (Ivory Coast)",
    value: 'CI',
    flag: '🇨🇮',
  },
  {
    name: 'Croatia',
    value: 'HR',
    flag: '🇭🇷',
  },
  {
    name: 'Cuba',
    value: 'CU',
    flag: '🇨🇺',
  },
  {
    name: 'Cyprus',
    value: 'CY',
    flag: '🇨🇾',
  },
  {
    name: 'Czech Republic',
    value: 'CZ',
    flag: '🇨🇿',
  },
  {
    name: 'Denmark',
    value: 'DK',
    flag: '🇩🇰',
  },
  {
    name: 'Djibouti',
    value: 'DJ',
    flag: '🇩🇯',
  },
  {
    name: 'Dominica',
    value: 'DM',
    flag: '🇩🇲',
  },
  {
    name: 'Dominican Republic',
    value: 'DO',
    flag: '🇩🇴',
  },
  {
    name: 'East Timor',
    value: 'TL',
    flag: '🇹🇱',
  },
  {
    name: 'Ecuador',
    value: 'EC',
    flag: '🇪🇨',
  },
  {
    name: 'Egypt',
    value: 'EG',
    flag: '🇪🇬',
  },
  {
    name: 'El Salvador',
    value: 'SV',
    flag: '🇸🇻',
  },
  {
    name: 'Equatorial Guinea',
    value: 'GQ',
    flag: '🇬🇶',
  },
  {
    name: 'Eritrea',
    value: 'ER',
    flag: '🇪🇷',
  },
  {
    name: 'Estonia',
    value: 'EE',
    flag: '🇪🇪',
  },
  {
    name: 'Ethiopia',
    value: 'ET',
    flag: '🇪🇹',
  },
  {
    name: 'Falkland Islands',
    value: 'FK',
    flag: '🇫🇰',
  },
  {
    name: 'Faroe Islands',
    value: 'FO',
    flag: '🇫🇴',
  },
  {
    name: 'Fiji Islands',
    value: 'FJ',
    flag: '🇫🇯',
  },
  {
    name: 'Finland',
    value: 'FI',
    flag: '🇫🇮',
  },
  {
    name: 'France',
    value: 'FR',
    flag: '🇫🇷',
  },
  {
    name: 'French Guiana',
    value: 'GF',
    flag: '🇬🇫',
  },
  {
    name: 'French Polynesia',
    value: 'PF',
    flag: '🇵🇫',
  },
  {
    name: 'French Southern Territories',
    value: 'TF',
    flag: '🇹🇫',
  },
  {
    name: 'Gabon',
    value: 'GA',
    flag: '🇬🇦',
  },
  {
    name: 'Gambia The',
    value: 'GM',
    flag: '🇬🇲',
  },
  {
    name: 'Georgia',
    value: 'GE',
    flag: '🇬🇪',
  },
  {
    name: 'Germany',
    value: 'DE',
    flag: '🇩🇪',
  },
  {
    name: 'Ghana',
    value: 'GH',
    flag: '🇬🇭',
  },
  {
    name: 'Gibraltar',
    value: 'GI',
    flag: '🇬🇮',
  },
  {
    name: 'Greece',
    value: 'GR',
    flag: '🇬🇷',
  },
  {
    name: 'Greenland',
    value: 'GL',
    flag: '🇬🇱',
  },
  {
    name: 'Grenada',
    value: 'GD',
    flag: '🇬🇩',
  },
  {
    name: 'Guadeloupe',
    value: 'GP',
    flag: '🇬🇵',
  },
  {
    name: 'Guam',
    value: 'GU',
    flag: '🇬🇺',
  },
  {
    name: 'Guatemala',
    value: 'GT',
    flag: '🇬🇹',
  },
  {
    name: 'Guernsey and Alderney',
    value: 'GG',
    flag: '🇬🇬',
  },
  {
    name: 'Guinea',
    value: 'GN',
    flag: '🇬🇳',
  },
  {
    name: 'Guinea-Bissau',
    value: 'GW',
    flag: '🇬🇼',
  },
  {
    name: 'Guyana',
    value: 'GY',
    flag: '🇬🇾',
  },
  {
    name: 'Haiti',
    value: 'HT',
    flag: '🇭🇹',
  },
  {
    name: 'Heard Island and McDonald Islands',
    value: 'HM',
    flag: '🇭🇲',
  },
  {
    name: 'Honduras',
    value: 'HN',
    flag: '🇭🇳',
  },
  {
    name: 'Hong Kong S.A.R.',
    value: 'HK',
    flag: '🇭🇰',
  },
  {
    name: 'Hungary',
    value: 'HU',
    flag: '🇭🇺',
  },
  {
    name: 'Iceland',
    value: 'IS',
    flag: '🇮🇸',
  },
  {
    name: 'India',
    value: 'IN',
    flag: '🇮🇳',
  },
  {
    name: 'Indonesia',
    value: 'ID',
    flag: '🇮🇩',
  },
  {
    name: 'Iran',
    value: 'IR',
    flag: '🇮🇷',
  },
  {
    name: 'Iraq',
    value: 'IQ',
    flag: '🇮🇶',
  },
  {
    name: 'Ireland',
    value: 'IE',
    flag: '🇮🇪',
  },
  {
    name: 'Israel',
    value: 'IL',
    flag: '🇮🇱',
  },
  {
    name: 'Italy',
    value: 'IT',
    flag: '🇮🇹',
  },
  {
    name: 'Jamaica',
    value: 'JM',
    flag: '🇯🇲',
  },
  {
    name: 'Japan',
    value: 'JP',
    flag: '🇯🇵',
  },
  {
    name: 'Jersey',
    value: 'JE',
    flag: '🇯🇪',
  },
  {
    name: 'Jordan',
    value: 'JO',
    flag: '🇯🇴',
  },
  {
    name: 'Kazakhstan',
    value: 'KZ',
    flag: '🇰🇿',
  },
  {
    name: 'Kenya',
    value: 'KE',
    flag: '🇰🇪',
  },
  {
    name: 'Kiribati',
    value: 'KI',
    flag: '🇰🇮',
  },
  {
    name: 'North Korea',
    value: 'KP',
    flag: '🇰🇵',
  },
  {
    name: 'South Korea',
    value: 'KR',
    flag: '🇰🇷',
  },
  {
    name: 'Kuwait',
    value: 'KW',
    flag: '🇰🇼',
  },
  {
    name: 'Kyrgyzstan',
    value: 'KG',
    flag: '🇰🇬',
  },
  {
    name: 'Laos',
    value: 'LA',
    flag: '🇱🇦',
  },
  {
    name: 'Latvia',
    value: 'LV',
    flag: '🇱🇻',
  },
  {
    name: 'Lebanon',
    value: 'LB',
    flag: '🇱🇧',
  },
  {
    name: 'Lesotho',
    value: 'LS',
    flag: '🇱🇸',
  },
  {
    name: 'Liberia',
    value: 'LR',
    flag: '🇱🇷',
  },
  {
    name: 'Libya',
    value: 'LY',
    flag: '🇱🇾',
  },
  {
    name: 'Liechtenstein',
    value: 'LI',
    flag: '🇱🇮',
  },
  {
    name: 'Lithuania',
    value: 'LT',
    flag: '🇱🇹',
  },
  {
    name: 'Luxembourg',
    value: 'LU',
    flag: '🇱🇺',
  },
  {
    name: 'Macau S.A.R.',
    value: 'MO',
    flag: '🇲🇴',
  },
  {
    name: 'Macedonia',
    value: 'MK',
    flag: '🇲🇰',
  },
  {
    name: 'Madagascar',
    value: 'MG',
    flag: '🇲🇬',
  },
  {
    name: 'Malawi',
    value: 'MW',
    flag: '🇲🇼',
  },
  {
    name: 'Malaysia',
    value: 'MY',
    flag: '🇲🇾',
  },
  {
    name: 'Maldives',
    value: 'MV',
    flag: '🇲🇻',
  },
  {
    name: 'Mali',
    value: 'ML',
    flag: '🇲🇱',
  },
  {
    name: 'Malta',
    value: 'MT',
    flag: '🇲🇹',
  },
  {
    name: 'Man (Isle of)',
    value: 'IM',
    flag: '🇮🇲',
  },
  {
    name: 'Marshall Islands',
    value: 'MH',
    flag: '🇲🇭',
  },
  {
    name: 'Martinique',
    value: 'MQ',
    flag: '🇲🇶',
  },
  {
    name: 'Mauritania',
    value: 'MR',
    flag: '🇲🇷',
  },
  {
    name: 'Mauritius',
    value: 'MU',
    flag: '🇲🇺',
  },
  {
    name: 'Mayotte',
    value: 'YT',
    flag: '🇾🇹',
  },
  {
    name: 'Mexico',
    value: 'MX',
    flag: '🇲🇽',
  },
  {
    name: 'Micronesia',
    value: 'FM',
    flag: '🇫🇲',
  },
  {
    name: 'Moldova',
    value: 'MD',
    flag: '🇲🇩',
  },
  {
    name: 'Monaco',
    value: 'MC',
    flag: '🇲🇨',
  },
  {
    name: 'Mongolia',
    value: 'MN',
    flag: '🇲🇳',
  },
  {
    name: 'Montenegro',
    value: 'ME',
    flag: '🇲🇪',
  },
  {
    name: 'Montserrat',
    value: 'MS',
    flag: '🇲🇸',
  },
  {
    name: 'Morocco',
    value: 'MA',
    flag: '🇲🇦',
  },
  {
    name: 'Mozambique',
    value: 'MZ',
    flag: '🇲🇿',
  },
  {
    name: 'Myanmar',
    value: 'MM',
    flag: '🇲🇲',
  },
  {
    name: 'Namibia',
    value: 'NA',
    flag: '🇳🇦',
  },
  {
    name: 'Nauru',
    value: 'NR',
    flag: '🇳🇷',
  },
  {
    name: 'Nepal',
    value: 'NP',
    flag: '🇳🇵',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    value: 'BQ',
    flag: '🇧🇶',
  },
  {
    name: 'Netherlands',
    value: 'NL',
    flag: '🇳🇱',
  },
  {
    name: 'New Caledonia',
    value: 'NC',
    flag: '🇳🇨',
  },
  {
    name: 'New Zealand',
    value: 'NZ',
    flag: '🇳🇿',
  },
  {
    name: 'Nicaragua',
    value: 'NI',
    flag: '🇳🇮',
  },
  {
    name: 'Niger',
    value: 'NE',
    flag: '🇳🇪',
  },
  {
    name: 'Nigeria',
    value: 'NG',
    flag: '🇳🇬',
  },
  {
    name: 'Niue',
    value: 'NU',
    flag: '🇳🇺',
  },
  {
    name: 'Norfolk Island',
    value: 'NF',
    flag: '🇳🇫',
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MP',
    flag: '🇲🇵',
  },
  {
    name: 'Norway',
    value: 'NO',
    flag: '🇳🇴',
  },
  {
    name: 'Oman',
    value: 'OM',
    flag: '🇴🇲',
  },
  {
    name: 'Pakistan',
    value: 'PK',
    flag: '🇵🇰',
  },
  {
    name: 'Palau',
    value: 'PW',
    flag: '🇵🇼',
  },
  {
    name: 'Palestinian Territory Occupied',
    value: 'PS',
    flag: '🇵🇸',
  },
  {
    name: 'Panama',
    value: 'PA',
    flag: '🇵🇦',
  },
  {
    name: 'Papua new Guinea',
    value: 'PG',
    flag: '🇵🇬',
  },
  {
    name: 'Paraguay',
    value: 'PY',
    flag: '🇵🇾',
  },
  {
    name: 'Peru',
    value: 'PE',
    flag: '🇵🇪',
  },
  {
    name: 'Philippines',
    value: 'PH',
    flag: '🇵🇭',
  },
  {
    name: 'Pitcairn Island',
    value: 'PN',
    flag: '🇵🇳',
  },
  {
    name: 'Poland',
    value: 'PL',
    flag: '🇵🇱',
  },
  {
    name: 'Portugal',
    value: 'PT',
    flag: '🇵🇹',
  },
  {
    name: 'Puerto Rico',
    value: 'PR',
    flag: '🇵🇷',
  },
  {
    name: 'Qatar',
    value: 'QA',
    flag: '🇶🇦',
  },
  {
    name: 'Reunion',
    value: 'RE',
    flag: '🇷🇪',
  },
  {
    name: 'Romania',
    value: 'RO',
    flag: '🇷🇴',
  },
  {
    name: 'Russia',
    value: 'RU',
    flag: '🇷🇺',
  },
  {
    name: 'Rwanda',
    value: 'RW',
    flag: '🇷🇼',
  },
  {
    name: 'Saint Helena',
    value: 'SH',
    flag: '🇸🇭',
  },
  {
    name: 'Saint Kitts And Nevis',
    value: 'KN',
    flag: '🇰🇳',
  },
  {
    name: 'Saint Lucia',
    value: 'LC',
    flag: '🇱🇨',
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'PM',
    flag: '🇵🇲',
  },
  {
    name: 'Saint Vincent And The Grenadines',
    value: 'VC',
    flag: '🇻🇨',
  },
  {
    name: 'Saint-Barthelemy',
    value: 'BL',
    flag: '🇧🇱',
  },
  {
    name: 'Saint-Martin (French part)',
    value: 'MF',
    flag: '🇲🇫',
  },
  {
    name: 'Samoa',
    value: 'WS',
    flag: '🇼🇸',
  },
  {
    name: 'San Marino',
    value: 'SM',
    flag: '🇸🇲',
  },
  {
    name: 'Sao Tome and Principe',
    value: 'ST',
    flag: '🇸🇹',
  },
  {
    name: 'Saudi Arabia',
    value: 'SA',
    flag: '🇸🇦',
  },
  {
    name: 'Senegal',
    value: 'SN',
    flag: '🇸🇳',
  },
  {
    name: 'Serbia',
    value: 'RS',
    flag: '🇷🇸',
  },
  {
    name: 'Seychelles',
    value: 'SC',
    flag: '🇸🇨',
  },
  {
    name: 'Sierra Leone',
    value: 'SL',
    flag: '🇸🇱',
  },
  {
    name: 'Singapore',
    value: 'SG',
    flag: '🇸🇬',
  },
  {
    name: 'Slovakia',
    value: 'SK',
    flag: '🇸🇰',
  },
  {
    name: 'Slovenia',
    value: 'SI',
    flag: '🇸🇮',
  },
  {
    name: 'Solomon Islands',
    value: 'SB',
    flag: '🇸🇧',
  },
  {
    name: 'Somalia',
    value: 'SO',
    flag: '🇸🇴',
  },
  {
    name: 'South Africa',
    value: 'ZA',
    flag: '🇿🇦',
  },
  {
    name: 'South Georgia',
    value: 'GS',
    flag: '🇬🇸',
  },
  {
    name: 'South Sudan',
    value: 'SS',
    flag: '🇸🇸',
  },
  {
    name: 'Spain',
    value: 'ES',
    flag: '🇪🇸',
  },
  {
    name: 'Sri Lanka',
    value: 'LK',
    flag: '🇱🇰',
  },
  {
    name: 'Sudan',
    value: 'SD',
    flag: '🇸🇩',
  },
  {
    name: 'Suriname',
    value: 'SR',
    flag: '🇸🇷',
  },
  {
    name: 'Svalbard And Jan Mayen Islands',
    value: 'SJ',
    flag: '🇸🇯',
  },
  {
    name: 'Swaziland',
    value: 'SZ',
    flag: '🇸🇿',
  },
  {
    name: 'Sweden',
    value: 'SE',
    flag: '🇸🇪',
  },
  {
    name: 'Switzerland',
    value: 'CH',
    flag: '🇨🇭',
  },
  {
    name: 'Syria',
    value: 'SY',
    flag: '🇸🇾',
  },
  {
    name: 'Taiwan',
    value: 'TW',
    flag: '🇹🇼',
  },
  {
    name: 'Tajikistan',
    value: 'TJ',
    flag: '🇹🇯',
  },
  {
    name: 'Tanzania',
    value: 'TZ',
    flag: '🇹🇿',
  },
  {
    name: 'Thailand',
    value: 'TH',
    flag: '🇹🇭',
  },
  {
    name: 'Togo',
    value: 'TG',
    flag: '🇹🇬',
  },
  {
    name: 'Tokelau',
    value: 'TK',
    flag: '🇹🇰',
  },
  {
    name: 'Tonga',
    value: 'TO',
    flag: '🇹🇴',
  },
  {
    name: 'Trinidad And Tobago',
    value: 'TT',
    flag: '🇹🇹',
  },
  {
    name: 'Tunisia',
    value: 'TN',
    flag: '🇹🇳',
  },
  {
    name: 'Turkey',
    value: 'TR',
    flag: '🇹🇷',
  },
  {
    name: 'Turkmenistan',
    value: 'TM',
    flag: '🇹🇲',
  },
  {
    name: 'Turks And Caicos Islands',
    value: 'TC',
    flag: '🇹🇨',
  },
  {
    name: 'Tuvalu',
    value: 'TV',
    flag: '🇹🇻',
  },
  {
    name: 'Uganda',
    value: 'UG',
    flag: '🇺🇬',
  },
  {
    name: 'Ukraine',
    value: 'UA',
    flag: '🇺🇦',
  },
  {
    name: 'United Arab Emirates',
    value: 'AE',
    flag: '🇦🇪',
  },
  {
    name: 'United Kingdom',
    value: 'GB',
    flag: '🇬🇧',
  },
  {
    name: 'United States',
    value: 'US',
    flag: '🇺🇸',
  },
  {
    name: 'United States Minor Outlying Islands',
    value: 'UM',
    flag: '🇺🇲',
  },
  {
    name: 'Uruguay',
    value: 'UY',
    flag: '🇺🇾',
  },
  {
    name: 'Uzbekistan',
    value: 'UZ',
    flag: '🇺🇿',
  },
  {
    name: 'Vanuatu',
    value: 'VU',
    flag: '🇻🇺',
  },
  {
    name: 'Vatican City State (Holy See)',
    value: 'VA',
    flag: '🇻🇦',
  },
  {
    name: 'Venezuela',
    value: 'VE',
    flag: '🇻🇪',
  },
  {
    name: 'Vietnam',
    value: 'VN',
    flag: '🇻🇳',
  },
  {
    name: 'Virgin Islands (British)',
    value: 'VG',
    flag: '🇻🇬',
  },
  {
    name: 'Virgin Islands (US)',
    value: 'VI',
    flag: '🇻🇮',
  },
  {
    name: 'Wallis And Futuna Islands',
    value: 'WF',
    flag: '🇼🇫',
  },
  {
    name: 'Western Sahara',
    value: 'EH',
    flag: '🇪🇭',
  },
  {
    name: 'Yemen',
    value: 'YE',
    flag: '🇾🇪',
  },
  {
    name: 'Zambia',
    value: 'ZM',
    flag: '🇿🇲',
  },
  {
    name: 'Zimbabwe',
    value: 'ZW',
    flag: '🇿🇼',
  },
  {
    name: 'Kosovo',
    value: 'XK',
    flag: '🇽🇰',
  },
  {
    name: 'Curaçao',
    value: 'CW',
    flag: '🇨🇼',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    value: 'SX',
    flag: '🇸🇽',
  },
]
