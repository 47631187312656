
import {
  Component, Vue, Prop, Watch, Ref,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Company from '@/models/Company'
import Profile from '@/models/Profile'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import AccessControl from '@/pages/Profile/Components/AccessControl.vue'
import Api from '@/models/Api'
import ViewModel from '@/models/ViewModel'
import countryCodes from '@/data/country_options'
import stateCodes from '@/data/state_options'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import WebMessage from '@/models/WebMessage'
import moment from 'moment'
import { invoice_group_options, invoice_detail_options } from '@/pages/Invoice/options'
import {
  InvoiceTemplates,
  InvoiceDeliveryMode,
  AgencyFeeModel,
  AgencyFeeMode,
  CompanyTypes,
  IndustryOptions,
  SubIndustryOptions,
} from '@/models/interface/Common'
import { percentageMask, currencyMask } from '@/models/interface/Masks'
import DynamicRatePicker from '@/components/DynamicRatePicker/DynamicRatePicker.vue'
import DeliveryGoal from '@/models/DeliveryGoal'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import EventTracker from '@/components/EventTracker/EventTracker.vue'
import RegionPicker from '@/components/RegionPicker/RegionPicker.vue'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'
import DeliveryGoals from './DeliveryGoals.vue'
// @ts-ignore
@Component({
  // @ts-ignore
  components: {
    Widget,
    SelectPicker,
    AccessControl,
    CompanyPicker,
    FormWizard,
    TabContent,
    FormInput,
    IconAction,
    DatePicker,
    DynamicRatePicker,
    DeliveryGoals,
    EventTracker,
    RegionPicker,
    MediaPackagePicker,
  },
})
export default class CompanyForm extends ViewModel {
  @Ref() readonly validator!: any

  @Ref() readonly formWizard: any

  @Ref() readonly eventTracker!: EventTracker

  @Prop({ default: '' })
  public id!: string

  @Prop({
    default: 'full',
  })
  public view_mode!: string

  @Prop({
    default: '',
  })
  public new_id!: string

  @Prop({
    default: true,
  })
  public show_sidebar!: boolean

  public budget_cycles: any = Company.option_list.budget_cycles

  public fiscal_close: any = Company.option_list.fiscal_close

  public hasErrors: boolean = false

  public mode: string = 'station'

  public title: string = 'Create Company'

  public company: Company = new Company()

  public busy = true

  public logo: any = null

  public logo_white: any = null

  public icon: any = null

  public logo_preview: any = null

  public logo_white_preview: any = null

  public icon_preview: any = null

  public step: number = 1

  public profile: Profile = new Profile()

  public invoicing_ids: object[] = []

  public invoicing_id: string | null = null

  public enable_link: boolean = false

  public email_picker_options: Array<any> = []

  public getToday: any = moment()

  public ref: any = 'Companies'

  @Prop({ default: '' })
  public limit_type!: string

  @Prop({ default: '' })
  public parent_id!: string

  public get macros() {
    return this.eventTracker?.macros
  }

  public get masks() {
    return {
      percentageMask,
      currencyMask,
    }
  }

  public get invoice_grouping() {
    return {
      invoice_group_options,
      invoice_detail_options,
      InvoiceTemplates,
      InvoiceDeliveryMode,
      AgencyFeeModel,
      AgencyFeeMode,
    }
  }

  public get country_options() {
    return countryCodes
  }

  public get state_options() {
    return stateCodes
  }

  public get station_validation() {
    if (this.company.type == 'station') {
      return 'required'
    }
    return ''
  }

  public get company_type_options() {
    if (this.user.isSuperAdmin) {
      return CompanyTypes
    }
    return CompanyTypes.filter((o: any) => o.value == 'advertiser' || o.value == 'station')
  }

  public get company_industry_options() {
    return IndustryOptions
  }

  public get company_sub_industry_options() {
    if (!SubIndustryOptions[this.company.industry]) {
      return []
    }
    return SubIndustryOptions[this.company.industry]
  }

  public get extra_fields() {
    return [
      {
        name: 'Client Name',
        value: 'client_name',
      },
      {
        name: 'Order',
        value: 'order',
      },
      {
        name: 'Product',
        value: 'product',
      },
      {
        name: 'Estimate',
        value: 'estimate',
      },
    ]
  }

  public addTag(newTag: string) {
    if (this.validateEmail(newTag)) {
      const tag = {
        name: newTag,
        value: newTag,
      }
      this.email_picker_options.push(tag)
      this.company.billing_info.billing_email.push(newTag)
    } else {
      WebMessage.error('Please enter a valid email.')
    }
  }

  public validateEmail(email: string) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return res.test(email.toLowerCase())
  }

  public onPermissionChange(model: string, action: string, access: boolean) {
    if (model == 'all' && action == 'all') {
      this.company.access.forEach(i => (i.access = access))
    } else if (access && action != 'view') {
      this.company.access[this.company.getAccessIndex(model, 'view')].access = true
    } else if (!access && action == 'view') {
      let idx = this.company.getAccessIndex(model, 'edit')

      if (idx >= 0) {
        this.company.access[idx].access = false
      }

      idx = this.company.getAccessIndex(model, 'delete')

      if (idx >= 0) {
        this.company.access[idx].access = false
      }
    }
  }

  public onSubmit() {
    if (this.hasErrors) {
      WebMessage.error('Please fix the overlapping dates')
      return
    }
    this.loading = true
    if (this.company.archived_at) {
      this.company.archived_at = moment(this.company.archived_at).format('YYYY-MM-DD HH:mm:ss')
    }
    this.company
      .save(this.logo, this.logo_white, this.icon)
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          if (this.view_mode == 'sidebar') {
            this.$emit('update:new_id', response.data.result.company.id)
            this.init()
            this.$emit('update:show_sidebar', false)
          } else {
            let r: any = { name: this.ref }
            if (this.$route.query && this.$route.query.id) {
              r = { ...r, params: { id: this.$route.query.id } }
            }
            this.$router.push(r)
          }
        }
        this.$emit('saved')
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    if (this.view_mode == 'sidebar') {
      this.$emit('update:show_sidebar', false)
    } else {
      let r: any = { name: this.ref }
      if (this.$route.query && this.$route.query.id) {
        r = { ...r, params: { id: this.$route.query.id } }
      }
      this.$router.push(r)
    }
  }

  @Watch('show_sidebar')
  public onSidebarStatus() {
    this.init()
  }

  public init() {
    this.busy = true
    if (this.id) {
      this.loading = true
      Company.find(this.id).then(o => {
        if (o instanceof Company) {
          this.company = o
          setTimeout(() => {
            // @ts-ignore
            this.formWizard.activateAll()
          }, 1000)
        } else {
          this.newCompany()
        }
        this.loading = false
        this.initUI()
        if (this.company.archived_at) {
          this.getToday = this.company.archived_at
        }
      })

      this.title = `Edit ${this.mode}`
    } else {
      this.newCompany()
      this.initUI()
    }
  }

  public created() {
    const { query } = this.$route

    if (query.ref) {
      this.ref = query.ref
    }
  }

  public initUI() {
    if (this.user.isSuperAdmin) {
      this.mode = 'entity'
    }

    if (this.company.id) {
      this.title = `Edit ${this.mode}`
    } else {
      this.title = `Create ${this.mode}`
    }

    this.email_picker_options = this.company.billing_info.billing_email.map((o: string) => ({
      name: o,
      value: o,
    }))

    this.invoicing_ids = []
    // Disabled - NOT in use
    /*
    if (this.user.isSuperAdmin) {
      let api = new Api()
      api.get('company/invoicing').then((response) => {
        this.invoicing_ids = response.data

        this.enable_link = this.company.invoicing_id == null
        if (!this.enable_link) this.invoicing_id = this.company.invoicing_id
      })
    } */

    this.busy = false
  }

  private newCompany() {
    this.company = new Company()

    if (!this.user.isSuperAdmin) {
      this.company.owner_id = this.user.company_id
    }

    if (this.limit_type != '') {
      this.company.type = this.limit_type
      if (this.company.type == 'station') {
        this.company.parent_id = this.parent_id
      } else if (this.company.type == 'advertiser') {
        this.company.owner_id = this.parent_id
      }
    }
  }

  @Watch('parent_id')
  public updateParentId() {
    if (this.limit_type == 'advertiser') {
      this.company.owner_id = this.parent_id
      this.company.parent_id = null
    } else if (this.limit_type == 'station') {
      this.company.parent_id = this.parent_id
      this.company.owner_id = null
    } else {
      this.company.parent_id = null
      this.company.owner_id = null
    }
  }

  @Watch('limit_type')
  public onTypeLimitChange() {
    this.company.type = this.limit_type
    if (this.limit_type == 'advertiser') {
      this.company.owner_id = this.parent_id
      this.company.parent_id = null
    } else if (this.limit_type == 'station') {
      this.company.parent_id = this.parent_id
      this.company.owner_id = null
    } else {
      this.company.parent_id = null
      this.company.owner_id = null
    }
  }

  public mounted() {
    this.init()
  }

  @Watch('logo')
  public onChangeLogo() {
    if (this.logo) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.logo_preview = reader.result
      }
      reader.readAsDataURL(this.logo)
    } else if (this.logo_preview) {
      this.logo_preview = null
    }
  }

  @Watch('logo_white')
  public onChangeLogoWhite() {
    if (this.logo_white) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.logo_white_preview = reader.result
      }
      reader.readAsDataURL(this.logo_white)
    } else if (this.logo_white_preview) {
      this.logo_white_preview = null
    }
  }

  @Watch('icon')
  public onChangeIcon() {
    if (this.icon) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.icon_preview = reader.result
      }
      reader.readAsDataURL(this.icon)
    } else if (this.icon_preview) {
      this.icon_preview = null
    }
  }

  public nextStep(props: any) {
    if (!this.hasErrors) {
      props.nextTab()
    } else {
      WebMessage.error('Please fix the overlapping dates')
    }
  }

  public deleteLogo() {
    if (this.logo) {
      this.logo = null
      this.logo_preview = null
    } else {
      this.company.logo = null
    }
  }

  public deleteLogoWhite() {
    if (this.logo_white) {
      this.logo_white = null
      this.logo_white_preview = null
    } else {
      this.company.logo_white = null
    }
  }

  public deleteIcon() {
    if (this.icon) {
      this.icon = null
      this.icon_preview = null
    } else {
      this.company.icon = null
    }
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }
}
