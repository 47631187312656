
import { Component, Prop, Vue } from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'

@Component({
  components: {
    SelectPicker,
  },
})
export default class RegionPicker extends Vue {
  @Prop()
  public value!: any

  public options = [
    {
      name: 'East',
      value: 'east',
    },
    {
      name: 'Midwest',
      value: 'midwest',
    },
    {
      name: 'West',
      value: 'west',
    },
    {
      name: 'National',
      value: 'national',
    },
  ]

  public get local_value() {
    return this.value
  }

  public set local_value(d) {
    this.$emit('input', d)
  }
}
