import { CompanyTypes } from '@/models/interface/Common'
import SelectOption from '@/models/interface/SelectOption'

export default [
  {
    key: 'name',
    label: 'Name',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    class: '',
  },
  {
    key: 'type',
    label: 'Type',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    class: 'text-center',
    filter_type: 'picker',
    options: [
      new SelectOption('Type', ''),
      ...CompanyTypes,
    ],
  },
  {
    key: 'parent_id',
    label: 'Parent/Owner',
    thClass: 'align-middle text-center',
    sortable: true,
    class: 'text-center',
    show: true,
  },
  {
    key: 'active_distribution_goal',
    label: 'Distribution Goal',
    thClass: 'align-middle text-center',
    sortable: false,
    class: 'text-center',
    show: true,
  },
  {
    key: 'active_distribution_cap',
    label: 'Distribution Cap',
    thClass: 'align-middle text-center',
    sortable: false,
    class: 'text-center',
    show: true,
  },
  {
    key: 'created_at',
    label: 'Created At',
    thClass: 'align-middle text-center',
    sortable: true,
    class: 'text-center',
    show: false,
    filter_type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    thClass: 'align-middle text-center',
    sortable: true,
    class: 'text-center',
    show: true,
    filter_type: 'date',
  },
  {
    key: 'action',
    label: 'Action',
    thClass: 'align-middle text-center',
    sortable: false,
    show: true,
    class: 'text-center',
  },
]
